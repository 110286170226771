import { useState } from 'react'
import { useRouter } from 'next/router'
import { Row, Col, Modal, Button } from 'antd'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { register, clearError } from '../../../../redux/authSlice'
import { errorHandling, openNotification } from '../../../../utils/utils'

import Close from '../../../svgs/CloseModal'
import InputAffix from '../../form/InputAffix'
import PasswordInput from '../../form/PasswordInput'
import SocialButtons from '../../SocialButtons'

import Email from '../../../svgs/auth/Email'
import Human from '../../../svgs/auth/Human'

const Register = ({ open, setOpen }) => {
  const { t } = useTranslation('HomeLayout')
  const router = useRouter()
  const dispatch = useDispatch()

  const { loading, errors } = useSelector((state) => state.auth)

  const [formValues, setFormValues] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    password_confirmation: '',
  })

  // SUBMITING FORM
  const handleSubmit = () => {
    let formData = new FormData()

    formData.set('lang', 'it')
    for (var key in formValues) {
      formData.append(key, formValues[key])
    }

    dispatch(register(formData))
      .unwrap()
      .then(() => {
        if (localStorage.getItem('SMARTCAF_conversation_token')) {
          router.push('/dashboard/tickets')
        } else if (localStorage.getItem('redirection_link')) {
          router.push(localStorage.getItem('redirection_link'))
          localStorage.removeItem('redirection_link')
        } else if (localStorage.getItem('SMARTCAF_SERVICE_ID')) {
          router.push(
            `/dashboard/services/${localStorage.getItem(
              'SMARTCAF_SERVICE_ID'
            )}/request`
          )
          localStorage.removeItem('SMARTCAF_SERVICE_ID')
        } else {
          router.push('/dashboard/services')
        }
      })
      .catch((error) => {
        const message = errorHandling(error)
        openNotification('error', message)
      })
  }

  return (
    <Modal
      className='!w-[50rem]'
      title={null}
      open={open}
      onCancel={() => {
        localStorage.removeItem('SMARTCAF_SERVICE_ID')
        setOpen(false)
        dispatch(clearError())
      }}
      footer={null}
      closable={false}
      centered
    >
      <div className='text-center mb-24 mt-16'>
        <h3 className='text-xl font-semibold capitalize mb-8'>
          {t('creare un account')}
        </h3>
        <p className='text-dimmed leading-6 text-sm w-9/12 mx-auto'>
          {t('register.d')}
        </p>
      </div>

      <SocialButtons />

      <Row gutter={[16, 16]} className='form-type_5'>
        <Col xs={24} sm={12}>
          <InputAffix
            label={`${t('First Name')}`}
            placeholder={t('Enter your first name')}
            name='name'
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            error={errors?.name}
            icon={<Human style='h-20' />}
          />
        </Col>
        <Col xs={24} sm={12}>
          <InputAffix
            label={`${t('Last Name')}`}
            placeholder={t('Enter your last name')}
            name='lname'
            value={formValues.lname}
            onChange={(e) =>
              setFormValues({ ...formValues, lname: e.target.value })
            }
            error={errors?.lname}
            icon={<Human style='h-20' />}
          />
        </Col>
        <Col xs={24} sm={12}>
          <InputAffix
            label={`${t('form.email.label')}`}
            placeholder={t('form.email.ph')}
            name='email'
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            error={errors?.email}
            icon={<Email style='h-20' />}
          />
        </Col>
        <Col xs={24} sm={12}>
          <PasswordInput
            label={`${t('form.password.label')}`}
            placeholder={t('form.password.ph')}
            name='password'
            value={formValues.password}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                password: e.target.value,
                password_confirmation: e.target.value,
              })
            }
            error={errors?.password}
          />
        </Col>
      </Row>

      <Button
        className='block btn-green text-base font-semibold tracking-wide rounded-lg py-12 h-auto mt-32 mb-20 w-7/12 mx-auto'
        loading={loading}
        onClick={handleSubmit}
      >
        {t('register.submit')}
      </Button>

      <Close
        style='absolute top-28 right-36 cursor-pointer'
        onClick={() => {
          localStorage.removeItem('SMARTCAF_SERVICE_ID')
          setOpen(false)
          dispatch(clearError())
        }}
      />
    </Modal>
  )
}

export default Register
