import { Button } from 'antd'
import { useTranslation } from 'next-i18next'

import Google from '../svgs/auth/Google'
// import Facebook from '../svgs/auth/Facebook'

const SocialButtons = () => {
  const { t } = useTranslation('HomeLayout')

  return (
    <>
      <div className='flex justify-center gap-12'>
        <Button
          className='social-login !w-full'
          onClick={() => {
            if (process.env.NEXT_PUBLIC_ENVIRONMET === 'development') {
              window.open(
                'https://backend-dev.smartcaf.it/auth/google',
                '_self'
              )
            } else {
              window.open('https://backend.smartcaf.it/auth/google', '_self')
            }
          }}
        >
          <Google style='w-20' /> Google
        </Button>
        {/* <Button
          className='social-login'
          onClick={() => {
            if (process.env.NEXT_PUBLIC_ENVIRONMET === 'development') {
              window.open('https://backend-dev.smartcaf.it/auth/google', '_self')
            } else {
              window.open('https://backend.smartcaf.it/auth/google', '_self')
            }
          }}
        >
          <Facebook style='w-20' /> Facebook
        </Button> */}
      </div>

      <div className='relative w-10/12 mx-auto'>
        <hr className='my-32 border-[#6D6D6D] opacity-30' />
        <span className='bg-white text-[#626d7d] text-sm font-medium absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 py-4 px-16'>
          {t('Or log in with')}
        </span>
      </div>
    </>
  )
}

export default SocialButtons
