import { Input } from 'antd'

import Lock from '../../svgs/auth/Lock'

export default function PasswordInput({
  label,
  placeholder,
  name,
  value,
  onChange,
  error,
  style,
}) {
  return (
    <div className={`input ${style ? style : ''} ${error ? 'error' : ''}`}>
      <label htmlFor={name}>{label}</label>
      <Input.Password
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        prefix={<Lock style='h-20' />}
      />
      <small className='text-danger text-sm'>{error}</small>
    </div>
  )
}
