export default function Email({ style }) {
  return (
    <svg
      className={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2828_30499)'>
        <path
          d='M14.1665 17.0827H5.83317C3.33317 17.0827 1.6665 15.8327 1.6665 12.916V7.08268C1.6665 4.16602 3.33317 2.91602 5.83317 2.91602H14.1665C16.6665 2.91602 18.3332 4.16602 18.3332 7.08268V12.916C18.3332 15.8327 16.6665 17.0827 14.1665 17.0827Z'
          stroke='#AAAAAA'
          strokeWidth='1.25'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.1668 7.5L11.5585 9.58333C10.7002 10.2667 9.29183 10.2667 8.43349 9.58333L5.8335 7.5'
          stroke='#AAAAAA'
          strokeWidth='1.25'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2828_30499'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
