import { Input } from 'antd'

export default function InputAffix({label, placeholder, name, value, onChange, error, icon, style}) {
  return (
    <div className={`input ${style ? style : ''} ${error ? 'error' : ''}`}>
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        prefix={icon}
      />
      <small className='text-danger text-sm'>{error}</small>
    </div>
  )
}
