export default function Google({ style }) {
  return (
    <svg
      className={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3487_15884)'>
        <path
          d='M4.43242 12.0855L3.73625 14.6845L1.19176 14.7383C0.431328 13.3279 0 11.7141 0 9.9993C0 8.34105 0.403281 6.7773 1.11812 5.40039H1.11867L3.38398 5.8157L4.37633 8.06742C4.16863 8.67293 4.05543 9.32293 4.05543 9.9993C4.05551 10.7334 4.18848 11.4367 4.43242 12.0855Z'
          fill='#FBBB00'
        />
        <path
          d='M19.8252 8.13281C19.94 8.73773 19.9999 9.36246 19.9999 10.0009C19.9999 10.7169 19.9246 11.4152 19.7812 12.0889C19.2944 14.3812 18.0224 16.3828 16.2604 17.7993L16.2598 17.7987L13.4065 17.6532L13.0027 15.1323C14.1719 14.4466 15.0857 13.3735 15.567 12.0889H10.2197V8.13281H19.8252Z'
          fill='#518EF8'
        />
        <path
          d='M16.26 17.7975L16.2606 17.798C14.5469 19.1755 12.3699 19.9996 10.0001 19.9996C6.19189 19.9996 2.88092 17.8711 1.19189 14.7387L4.43256 12.0859C5.27705 14.3398 7.45123 15.9442 10.0001 15.9442C11.0957 15.9442 12.1221 15.648 13.0029 15.131L16.26 17.7975Z'
          fill='#28B446'
        />
        <path
          d='M16.383 2.30219L13.1434 4.95437C12.2319 4.38461 11.1544 4.05547 10 4.05547C7.39344 4.05547 5.17859 5.73348 4.37641 8.06812L1.11871 5.40109H1.11816C2.78246 2.1923 6.1352 0 10 0C12.4264 0 14.6511 0.864297 16.383 2.30219Z'
          fill='#F14336'
        />
      </g>
      <defs>
        <clipPath id='clip0_3487_15884'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
