import { useState } from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword, clearError } from '../../../../redux/authSlice'
import { errorHandling, openNotification } from '../../../../utils/utils'

import PasswordInput from '../../form/PasswordInput'
import Close from '../../../svgs/CloseModal'

export default function NewPassword({ open, setOpen }) {
  const { t } = useTranslation('HomeLayout')
  const dispatch = useDispatch()

  const { loading, resetPasswordToken } = useSelector((state) => state.auth)

  const [password, setPassword] = useState('')
  const [password_confirmation, setPassword_confirmation] = useState('')

  // HANDLE SUBMIT
  const handleSubmit = () => {
    dispatch(
      resetPassword({
        reset_password_token: resetPasswordToken,
        password,
        password_confirmation,
      })
    )
      .unwrap()
      .then((data) => {
        openNotification('success', data.message)
        setOpen(false)
      })
      .catch((error) => {
        const message = errorHandling(error)
        openNotification('error', message)
      })
  }

  return (
    <>
      <Modal
        className='!w-[32rem]'
        title={null}
        open={open}
        onCancel={() => {
          setOpen(false)
          dispatch(clearError())
        }}
        footer={null}
        closable={false}
        centered
      >
        <div className='text-center mb-24 mt-16'>
          <h3 className='text-xl font-semibold capitalize mb-8'>
            {t('Create New Password')}
          </h3>
        </div>

        <div className='form-type_5'>
          <PasswordInput
            label={`${t('form.password.label')}*`}
            placeholder={t('form.password.ph')}
            name='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style='mb-16'
          />
          <PasswordInput
            label={`${t('form.passwordConfirm.label')}*`}
            placeholder={t('form.password.ph')}
            name='password_confirmation'
            value={password_confirmation}
            onChange={(e) => setPassword_confirmation(e.target.value)}
          />
        </div>

        <Button
          className='btn-green text-base font-semibold tracking-wide rounded-lg py-12 w-full h-auto mt-24'
          loading={loading}
          onClick={handleSubmit}
        >
          {t('Confirm')}
        </Button>

        <Close
          style='absolute top-28 right-36 cursor-pointer'
          onClick={() => {
            setOpen(false)
            dispatch(clearError())
          }}
        />
      </Modal>
    </>
  )
}
