export default function Lock({ style, color = '#AAAAAA' }) {
  return (
    <svg
      className={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 8.33268V6.66602C5 3.90768 5.83333 1.66602 10 1.66602C14.1667 1.66602 15 3.90768 15 6.66602V8.33268'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99984 15.4167C11.1504 15.4167 12.0832 14.4839 12.0832 13.3333C12.0832 12.1827 11.1504 11.25 9.99984 11.25C8.84924 11.25 7.9165 12.1827 7.9165 13.3333C7.9165 14.4839 8.84924 15.4167 9.99984 15.4167Z'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1665 18.334H5.83317C2.49984 18.334 1.6665 17.5007 1.6665 14.1673V12.5007C1.6665 9.16732 2.49984 8.33398 5.83317 8.33398H14.1665C17.4998 8.33398 18.3332 9.16732 18.3332 12.5007V14.1673C18.3332 17.5007 17.4998 18.334 14.1665 18.334Z'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
