import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

const style = {
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const Loading = (props) => {
  const { align, cover, classes } = props
  return (
    <div
      className={`loading text-${align} cover-${cover} ${classes}`}
      style={style}
    >
      <Spin style={{ color: '#8FC045' }} indicator={Icon} />
    </div>
  )
}

Loading.propTypes = {
  size: PropTypes.string,
  cover: PropTypes.string,
}

Loading.defaultProps = {
  align: 'center',
  cover: 'inline',
}

export default Loading
