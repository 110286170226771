import { useState } from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendOTP, clearError } from '../../../../redux/authSlice'
import { errorHandling, openNotification } from '../../../../utils/utils'

import Close from '../../../svgs/CloseModal'
import Email from '../../../svgs/auth/Email'
import InputAffix from '../../form/InputAffix'
import VerifyOTP from './VerifyOTP'

const ResetPassword = ({ open, setOpen }) => {
  const { t } = useTranslation('HomeLayout')
  const dispatch = useDispatch()

  const { loading } = useSelector((state) => state.auth)

  const [verifyOTPOpen, setVerifyOTPOpen] = useState(false)
  const [email, setEmail] = useState('')

  // HANDLE SUBMIT
  const handleSubmit = () => {
    dispatch(sendOTP(email))
      .unwrap()
      .then(({ data }) => {
        openNotification('success', data.message)
        setOpen(false)
        setVerifyOTPOpen(true)
      })
      .catch((error) => openNotification('error', errorHandling(error)))
  }

  return (
    <>
      <Modal
        className='!w-[30rem]'
        title={null}
        open={open}
        onCancel={() => {
          setOpen(false)
          dispatch(clearError())
        }}
        footer={null}
        closable={false}
        centered
      >
        <div className='text-center mb-24 mt-16'>
          <h3 className='text-xl font-semibold capitalize mb-8'>
            {t('Reset Password')}
          </h3>
          <p className='text-dimmed leading-6 text-sm capitalize'>
            {t('Reset_Password_Desc')}
          </p>
        </div>

        <div className='form-type_5'>
          <InputAffix
            label={`${t('form.email.label')}*`}
            placeholder={t('form.email.ph')}
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<Email />}
            style='mb-20'
          />
        </div>

        <Button
          className='block btn-green text-base font-semibold tracking-wide rounded-lg py-12 h-auto mb-8 w-full'
          loading={loading}
          onClick={handleSubmit}
        >
          {t('Get Code')}
        </Button>

        <Close
          style='absolute top-28 right-36 cursor-pointer'
          onClick={() => {
            setOpen(false)
            dispatch(clearError())
          }}
        />
      </Modal>

      <VerifyOTP open={verifyOTPOpen} setOpen={setVerifyOTPOpen} />
    </>
  )
}

export default ResetPassword
