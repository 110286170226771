import { useState } from 'react'
import { Modal, Button } from 'antd'
import OtpInput from 'react-otp-input'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { verifyOTP, clearError } from '../../../../redux/authSlice'
import { errorHandling, openNotification } from '../../../../utils/utils'

import Close from '../../../svgs/CloseModal'
import NewPassword from './NewPassword'

const VerifyOTP = ({ open, setOpen }) => {
  const { t } = useTranslation('HomeLayout')
  const dispatch = useDispatch()

  const { updateLoading, resetPasswordEmail } = useSelector(
    (state) => state.auth
  )

  const [newPasswordOpen, setNewPasswordOpen] = useState(false)
  const [opt, setOpt] = useState('')

  // HANDLE SUBMIT
  const handleSubmit = () => {
    dispatch(verifyOTP({ email: resetPasswordEmail, opt }))
      .unwrap()
      .then((data) => {
        setOpt('')
        openNotification('success', data.message)
        setOpen(false)
        setNewPasswordOpen(true)
      })
      .catch((error) => openNotification('error', errorHandling(error)))
  }

  return (
    <>
      <Modal
        className='!w-[32rem]'
        title={null}
        open={open}
        onCancel={() => {
          setOpen(false)
          dispatch(clearError())
        }}
        footer={null}
        closable={false}
        centered
      >
        <div className='text-center mb-24 mt-16'>
          <h3 className='text-xl font-semibold capitalize mb-8'>
            {t('Check Your Messages')}
          </h3>
          <p className='text-dimmed leading-6 text-sm capitalize'>
            {t('Check_Messages_P1')} {resetPasswordEmail}{' '}
            {t('Check_Messages_P2')}
          </p>
        </div>

        <div className='otp-input-2 flex justify-center'>
          <OtpInput
            value={opt}
            onChange={setOpt}
            numInputs={6}
            renderSeparator={<span className='text-transparent'>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <Button
          className='btn-green text-base font-semibold tracking-wide rounded-lg py-12 w-full h-auto mt-24'
          loading={updateLoading}
          onClick={handleSubmit}
        >
          {t('Confirm')}
        </Button>

        <Close
          style='absolute top-28 right-36 cursor-pointer'
          onClick={() => {
            setOpen(false)
            dispatch(clearError())
          }}
        />
      </Modal>

      <NewPassword open={newPasswordOpen} setOpen={setNewPasswordOpen} />
    </>
  )
}

export default VerifyOTP
