export default function Human({ style }) {
  return (
    <svg
      className={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2828_30595)'>
        <path
          d='M10.1331 9.05964C10.0498 9.0513 9.9498 9.0513 9.85814 9.05964C7.8748 8.99297 6.2998 7.36797 6.2998 5.36797C6.2998 3.3263 7.9498 1.66797 9.9998 1.66797C12.0415 1.66797 13.6998 3.3263 13.6998 5.36797C13.6915 7.36797 12.1165 8.99297 10.1331 9.05964Z'
          stroke='#AAAAAA'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.9666 12.132C3.94993 13.482 3.94993 15.682 5.9666 17.0237C8.25827 18.557 12.0166 18.557 14.3083 17.0237C16.3249 15.6737 16.3249 13.4737 14.3083 12.132C12.0249 10.607 8.2666 10.607 5.9666 12.132Z'
          stroke='#AAAAAA'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2828_30595'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
