import { useState } from 'react'
import { useRouter } from 'next/router'
import { Modal, Button, Alert } from 'antd'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { login, clearError } from '../../../../redux/authSlice'
import { errorHandling, openNotification } from '../../../../utils/utils'

import ResetPassword from './ResetPassword'
import Close from '../../../svgs/CloseModal'
import Email from '../../../svgs/auth/Email'
import InputAffix from '../../form/InputAffix'
import PasswordInput from '../../form/PasswordInput'
import SocialButtons from '../../SocialButtons'
import Register from './Register'

const Login = ({ open, setOpen }) => {
  const { t } = useTranslation('HomeLayout')
  const router = useRouter()
  const dispatch = useDispatch()

  const { loading, errors, errorMsg } = useSelector((state) => state.auth)

  const [registerOpen, setRegisterOpen] = useState(false)
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  })

  // SUBMITING FORM
  const handleSubmit = () => {
    dispatch(login(formValues))
      .unwrap()
      .then(() => {
        if (router.query['redirection_link']) {
          router.push(router.query['redirection_link'])
        } else if (localStorage.getItem('redirection_link')) {
          router.push(localStorage.getItem('redirection_link'))
          localStorage.removeItem('redirection_link')
        } else if (localStorage.getItem('SMARTCAF_conversation_token')) {
          router.push('/dashboard/tickets')
        } else if (localStorage.getItem('SMARTCAF_SERVICE_ID')) {
          router.push(
            `/dashboard/services/${localStorage.getItem(
              'SMARTCAF_SERVICE_ID'
            )}/request`
          )
          localStorage.removeItem('SMARTCAF_SERVICE_ID')
        } else {
          router.push('/dashboard/services')
        }
      })
      .catch((error) => {
        const message = errorHandling(error)
        openNotification('error', message)
      })
  }

  return (
    <>
      <Modal
        className='!w-[30rem]'
        title={null}
        open={open}
        onCancel={() => {
          localStorage.removeItem('SMARTCAF_SERVICE_ID')
          setOpen(false)
          dispatch(clearError())
        }}
        footer={null}
        closable={false}
        centered
      >
        <div className='text-center mb-32 mt-16'>
          <h3 className='text-xl font-semibold capitalize mb-8'>
            {t('Accedi al tuo account')}
          </h3>
        </div>

        <SocialButtons />

        <div className='form-type_5'>
          <InputAffix
            label={`${t('form.email.label')}*`}
            placeholder={t('form.email.ph')}
            name='email'
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            error={errors?.email}
            icon={<Email />}
            style='mb-16'
          />

          <PasswordInput
            label={`${t('form.password.label')}*`}
            placeholder={t('form.password.ph')}
            name='password'
            value={formValues.password}
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            error={errors?.password}
            style='mb-8'
          />

          <span
            className='inline-block text-green text-sm font-medium cursor-pointer mb-32 flex justify-end'
            onClick={() => {
              localStorage.removeItem('SMARTCAF_SERVICE_ID')
              setResetPasswordOpen(true)
              setOpen(false)
              dispatch(clearError())
            }}
          >
            {t('Forgot Password ?')}
          </span>
        </div>

        {errorMsg && !errors && (
          <Alert className='mb-16' message={`${errorMsg}`} type='error' />
        )}

        <Button
          className='block btn-green text-base font-semibold tracking-wide rounded-lg py-12 h-auto mb-20 w-full'
          loading={loading}
          onClick={handleSubmit}
        >
          {t('login.submit')}
        </Button>

        <p className='text-[#AAA] text-sm text-center mt-16'>
          {t('login.footer')}{' '}
          <span
            className='text-green font-semibold cursor-pointer'
            onClick={() => {
              setOpen(false)
              setRegisterOpen(true)
            }}
          >
            {t('login.footer.link')}
          </span>
        </p>

        <Close
          style='absolute top-28 right-36 cursor-pointer'
          onClick={() => {
            localStorage.removeItem('SMARTCAF_SERVICE_ID')
            setOpen(false)
            dispatch(clearError())
          }}
        />
      </Modal>

      <Register open={registerOpen} setOpen={setRegisterOpen} />
      <ResetPassword open={resetPasswordOpen} setOpen={setResetPasswordOpen} />
    </>
  )
}

export default Login
